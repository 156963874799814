






import { Vue, Component } from "vue-property-decorator";
import VerticalMenu from "../../../components/VerticalMenu/VerticalMenu.vue"

@Component({
  components: {
    VerticalMenu
  },
})
export default class Main extends Vue {
  mounted() {
    this.$store.dispatch('auth/logout');
  }
}
